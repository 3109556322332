<div class="container">
  <div class="about card">
    <h1 class="header mb-3"><span class="about__text__one">About</span> Me</h1>
    <p class="text">
      I am a skilled and experienced Frontend Engineer with over five years of
      expertise in utilizing Angular, I am well-versed in creating dynamic and
      responsive web applications. My proficiency lies in crafting elegant user
      interfaces and enhancing user experiences through the effective
      implementation of Angular frameworks and best practices...<span
        (click)="toggleContent()"
        >{{ toggleState() }} More</span
      >
    </p>
    @if(showRemainingContents()){
    <p class="text">
      Throughout my career, I have consistently demonstrated a strong
      understanding of front-end development principles, along with a keen eye
      for design aesthetics. I possess a deep understanding of HTML, CSS, and
      JavaScript, which enables me to create clean and well-structured code that
      aligns with industry standards. My extensive experience in Angular has
      allowed me to develop a solid foundation in building scalable and
      maintainable applications.
    </p>
    <p class="text">
      I excel in collaborating with cross-functional teams and stakeholders to
      gather requirements, analyze user needs, and translate them into
      efficient, user-friendly front-end solutions. I am adept at working in
      Agile environments, ensuring the timely delivery of high-quality projects
      while maintaining effective communication and managing expectations.
    </p>
    <p class="text">
      In addition to my technical skills, I am passionate about staying
      up-to-date with the latest industry trends and advancements in front-end
      development. This drive for continuous learning enables me to incorporate
      emerging technologies and methodologies into my work, ensuring that I
      provide cutting-edge solutions.
    </p>
    <p class="text">
      Overall, I am a dedicated and motivated Frontend Developer with a proven
      track record in leveraging Angular to deliver exceptional web
      applications. With strong attention to detail and a passion for creating
      seamless user experiences, I am eager to contribute my skills to a dynamic
      team and drive innovation in the field of front-end development.
    </p>
    <p class="text">
      I'm available for remote work - if you would like to build something
      together, <a href="tel:+447733821980">get in touch?</a>
    </p>
    }

    <div class="dev-icons">
      <i class="fab fa-2x fa-html5"></i>
      <i class="fab fa-2x fa-css3-alt"></i>
      <i class="fab fa-2x fa-sass"></i>
      <i class="fab fa-2x fa-js"></i>
      <i class="fab fa-2x fa-angular"></i>
      <i class="fab fa-2x fa-wordpress"></i>
      <img src="assets/images/rxjs.png" alt="rxjs logo" class="rxjs" />
      <img src="assets/images/ngrx.svg" alt="ngrx logo" class="ngrx" />
      <img src="assets/images/graphql.png" alt="ngrx logo" class="graphql" />
    </div>
  </div>
  <div class="codewar-badge">
    <img
      src="https://www.codewars.com/users/lawaldare/badges/large"
      alt="codewar badge"
    />
  </div>
  <div class="card p-0 py-5">
    <h1 class="header mb-3">
      <span class="about__text__two">Work</span> Experience
    </h1>
    <div class="row">
      @for(job of jobs(); track job){
      <app-job-card class="col-sm-6" [job]="job" />
      }
    </div>
  </div>

  <div id="contact" class="contact card">
    <h1 class="header mb-3">
      <span class="about__text__three">Contact</span> Me
    </h1>
    <div class="contact-icons">
      @for(icon of contactIcons(); track icon){
      <app-contact-icon [icon]="icon" />
      }
    </div>
  </div>
</div>
