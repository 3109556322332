<div class="header-text d-flex flex-column justify-content-center align-items-center">
  <div class="content__box d-flex flex-column justify-content-center align-items-center">
    <span class="header">I am <span class="header__name">Dare Lawal,</span></span>
    <span class="text second-text text-center tw" #tw></span>
    <div class="buttons">

      <a href="assets/resume.pdf" download="dare-lawal-resume" class="btn__link btn__link--portfolio"
        id="right-button"><i class="fas fa-download position-relative" style="top:6px;"></i>&nbsp;&nbsp;Download
        resume
      </a>
    </div>

  </div>
  <img src="assets/images/blob-one.svg" alt="blob" class="blob blob__one">
  <img src="assets/images/blob-two.svg" alt="blob" class="blob blob__two">
</div>