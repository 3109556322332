<div
  id="card"
  [ngStyle]="{ 'border-left': '5px solid', 'border-color': job().themeColor }"
>
  <h4 class="card-header" [ngStyle]="{ 'background-color': job().themeColor }">
    {{ job().title }}
  </h4>
  <div class="card-body">
    <h5 class="card-title">{{ job().companyName }}</h5>
    <h6 class="card-title">{{ job().location }}</h6>
    <p class="card-text muted">{{ job().date }}</p>
  </div>
</div>
