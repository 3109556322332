<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <a
      (click)="setTitle('Home | Dare Lawal')"
      class="navbar-brand"
      routerLinkActive="router-link-active"
      [routerLink]="['/home']"
    >
      <img src="assets/logo.jpg" alt="logo" class="logo" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            (click)="setTitle('About | Dare Lawal')"
            class="nav-link"
            routerLinkActive="router-link-active"
            [routerLink]="['/about']"
            >About</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="setTitle('Portfolio | Dare Lawal')"
            class="nav-link"
            routerLinkActive="router-link-active"
            [routerLink]="['/portfolio']"
            >Portfolio</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="setTitle('Repos | Dare Lawal')"
            class="nav-link"
            routerLinkActive="router-link-active"
            [routerLink]="['/repos']"
            >Repos</a
          >
        </li>
        <!-- <li class="nav-item">
          <a (click)="setTitle( 'Playground | Dare Lawal' )" class="nav-link" routerLinkActive="router-link-active"
            [routerLink]="['/playground']">Playground</a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
